import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        // const len = fertilizer.length
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        var applicantName = ''
        if (data.request_type === 1) {
          applicantName = (i18n.locale === 'en') ? data.gn_organization_name : data.gn_organization_name_bn
        } else if (data.request_type === 2) {
          applicantName = (i18n.locale === 'en') ? data.receiver_name : data.receiver_name_bn
        } else {
          applicantName = (i18n.locale === 'en') ? data.farmer_name : data.farmer_name_bn
        }
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  { text: vm.$t('globalTrans.date'), style: 'th' },
                  { text: data.dis_date, style: 'td' },
                  { text: vm.$t('germplasm.request_type'), style: 'th' },
                  { text: data.request_type === 1 ? vm.$t('germplasm.germplasm_organization') : vm.$t('germplasm.receiver_code'), style: 'td' }
                ],
                [
                  { text: vm.$t('germplasm.name'), style: 'th' },
                  { text: applicantName, style: 'td', colSpan: 3 },
                  { },
                  { }
                ],
                [
                    { text: vm.$t('germplasm.contact_number'), style: 'th' },
                    { text: vm.$n(data.contact_no, { useGrouping: false }), style: 'td' },
                    { text: vm.$t('globalTrans.email'), style: 'th' },
                    { text: data.email, style: 'td' }
                  ],
                  [
                    { text: vm.$t('globalTrans.address'), style: 'th' },
                    { text: (i18n.locale === 'en') ? data.address : data.address_bn, style: 'td' },
                    { text: vm.$t('fertilizerConfig.tin_no'), style: 'th' },
                    { text: data.tin_no, style: 'td' }
                  ],
                  [
                    { text: vm.$t('germplasm.nid'), style: 'th' },
                    { text: (i18n.locale === 'en') ? data.nid : data.nid, style: 'td' },
                    { text: vm.$t('germplasm.trade_license'), style: 'th' },
                    { text: (i18n.locale === 'en') ? data.trade_license : data.trade_license, style: 'td' }
                  ],
                  [
                    { text: vm.$t('germplasm.crop_type'), style: 'th' },
                    { text: (i18n.locale === 'en') ? data.crop_type : data.crop_type_bn, style: 'td' },
                    { text: vm.$t('germConfig.cropName'), style: 'th' },
                    { text: (i18n.locale === 'en') ? data.crop_name : data.crop_name_bn, style: 'td' }
                  ],
                  [
                    { text: vm.$t('germplasm.variety'), style: 'th' },
                    { text: data.variety_name, style: 'td' },
                    { text: vm.$t('germplasm.season'), style: 'th' },
                    { text: data.season_name, style: 'td' }
                  ],
                  [
                    { text: vm.$t('germplasm.number_of_germplasm_needed'), style: 'th' },
                    { text: vm.$n(data.number_of_germplasm), style: 'td' },
                    { text: vm.$t('germplasm.specific_character_of_the_variety'), style: 'th' },
                    { text: (i18n.locale === 'en') ? data.specific_character_of_variety : data.specific_character_of_variety_bn, style: 'td' }
                  ],
                [
                  { text: vm.$t('germplasm.unit'), style: 'th' },
                  { text: data.unit_name, style: 'td' },
                  { text: vm.$t('procurement.quantity'), style: 'th' },
                  { text: vm.$n(data.quantity, { useGrouping: false }), style: 'td' }
                ],
                [
                  { text: vm.$t('germplasm.distribute_quantity'), style: 'th' },
                  { text: vm.$n(data.distribute_quantity, { useGrouping: false }), style: 'td' },
                  { text: vm.$t('germConfig.remarks'), style: 'th' },
                  { text: (i18n.locale === 'en') ? data.dis_remarks : data.dis_remarks_bn, style: 'td' }
                ]
              ]
            }
          }
        )
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        styles: {
            th: {
              bold: true,
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            fertilizer: {
              fontSize: 10,
              margin: [10, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 0]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
