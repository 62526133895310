<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <!-- <ValidationProvider name="Organization" vid='org_id' rules="required|min_value:1">
                      <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.organization')"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="formData.org_id"
                            :options="organizationtList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                        </ValidationProvider> -->
                      <ValidationProvider name="Request_Type" vid='request_type' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="warehouse_state"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('germplasm.request_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio-group
                                v-model="formData.request_type"
                                :options="options"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    <b-overlay class="mt-4" :show="loading">
                    <div>
                    <b-tabs content-class="mt-3 ml-4">
                    <b-tab :active="select"><p>
                      <b-row>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                          <ValidationProvider name="Germplasm Organization Name (En)"  vid="germplasm_organization_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="germplasm_organization_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('germplasm.germplasm_organization_name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="germplasm_organization_name"
                                  v-model="formData.gn_organization_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                          <ValidationProvider name="Germplasm Organization Name (Bn)"  vid="germplasm_organization_name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="germplasm_organization_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('germplasm.germplasm_organization_name') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="germplasm_organization_name_bn"
                                  v-model="formData.gn_organization_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                          <ValidationProvider name="Contact Number" vid="contact_no" :rules="`required`">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="contact_no"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.contact_number')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="contact_no"
                                v-model="formData.contact_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                          <ValidationProvider name="Email" vid="email" :rules="`required|email`">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="email"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="email"
                                v-model="formData.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                          <ValidationProvider name="Address" vid="address" :rules="`required`">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="address"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="address"
                                v-model="formData.address"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                            <ValidationProvider name="Address Bn" vid="address_bn" :rules="`required`">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="address_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="address"
                                    v-model="formData.address_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                            <ValidationProvider name="TIN No." vid="tin_no" :rules="`required`">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="tin_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('fertilizerConfig.tin_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="tin_no"
                                    v-model="formData.tin_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <b-col lg="6" sm="12">
                        </b-col>
                      </b-row>
                    </p></b-tab>
                    <b-tab :active="select2">
                      <b-row>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                          <ValidationProvider name="Receiver Name (En)"  vid="receiver_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="receiver_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('germplasm.receiver_name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="receiver_name"
                                  v-model="formData.receiver_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                          <ValidationProvider name="Receiver Name (Bn)"  vid="receiver_name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="receiver_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('germplasm.receiver_name') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="receiver_name_bn"
                                  v-model="formData.receiver_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                          <ValidationProvider name="NID" vid="nid" :rules="`required`">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="nid"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.nid')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="nid"
                                  v-model="formData.nid"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                          <ValidationProvider name="TIN No." vid="tin_no" :rules="`required`">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="tin_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerConfig.tin_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="tin_no"
                                  v-model="formData.tin_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                          <ValidationProvider name="Trade License" vid="trade_license" :rules="`required`">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="trade_license"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.trade_license')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="trade_license"
                                  v-model="formData.trade_license"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                        <ValidationProvider name="Contact Number" vid="contact_number" :rules="`required`">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="contact_number"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.contact_number')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="contact_number"
                              v-model="formData.contact_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                        <ValidationProvider name="Email" vid="email" :rules="`required|email`">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="email"
                              v-model="formData.email"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                        <ValidationProvider name="Address" vid="address" :rules="`required`">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="address"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="address"
                              v-model="formData.address"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                          <ValidationProvider name="Address Bn" vid="address_bn" :rules="`required`">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="address"
                                  v-model="formData.address_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab :active="select3"><p>
                      <b-row><b-col lg="6" sm="12">
                        <ValidationProvider name="Farmer Name (En)" vid="farmer_name" :rules="rule3">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_name_en"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.farmer_name_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="farmer_name_en"
                              v-model="formData.farmer_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Farmer Name (Bn)" vid="farmer_name_bn" :rules="rule3">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.farmer_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="farmer_name_en"
                              v-model="formData.farmer_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="NID" vid="nid" :rules="rule3">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.nid')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="farmer_name_en"
                              v-model="formData.nid"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Contact Number" vid="contact_number" :rules="rule3">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="contact_number"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.contact_number')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="contact_number"
                              v-model="formData.contact_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Email" vid="email" :rules="rule3">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="email"
                              v-model="formData.email"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Address" vid="address" :rules="rule3">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.address')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="address"
                              v-model="formData.address"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                      </b-row>
                    </p></b-tab>
                    <b-row>
                      <!-- <b-col lg="6" sm="12">
                        <ValidationProvider name="Assession Number" vid="accession_number" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="accession_number"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.accession_number') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="accession_number"
                              v-model="formData.accession_number"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col> -->
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Production Date" vid="dis_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="dis_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.production_date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                v-model="formData.dis_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div>
                                <p class="text-danger"> {{ dateerror }} </p>
                              </div>
                              <div class="invalid-feedback d-block" v-if="errors.length">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="tin"
                        >
                            <template v-slot:label>
                            {{$t('germplasm.crop_type')}}
                            </template>
                            <b-form-input
                            readonly
                            id="tin"
                            v-model="formData.crop_type"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="tin"
                        >
                            <template v-slot:label>
                            {{$t('germConfig.cropName')}}
                            </template>
                            <b-form-input
                            readonly
                            id="tin"
                            v-model="formData.crop_name"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="tin"
                        >
                            <template v-slot:label>
                            {{$t('germConfig.scientific_name')}}
                            </template>
                            <b-form-input
                            readonly
                            id="tin"
                            v-model="formData.scientific_name"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="tin"
                        >
                            <template v-slot:label>
                            {{$t('germConfig.english_name')}}
                            </template>
                            <b-form-input
                            readonly
                            id="tin"
                            v-model="formData.english_name"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Unit" vid="unit" rules="">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="unit"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('germplasm.unit') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              readonly
                              plain
                              v-model="formData.unit"
                              id="unit"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Quantity" vid="quantity" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="quantity"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('germplasm.approved_quantity')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            readonly
                            id="mobile_no"
                            v-model="formData.quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Quantity" vid="distribute_quantity" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="distribute_quantity"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('germplasm.distribute_quantity')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="distribute_quantity"
                            v-model="formData.distribute_quantity"
                            @keyup="getActualQuantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks')}}
                                </template>
                                <b-form-textarea
                                    rows="4"
                                    id="remarks"
                                    v-model="formData.dis_remarks"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks_bn')}}
                                </template>
                                <b-form-textarea
                                    rows="4"
                                    id="remarks"
                                    v-model="formData.dis_remarks_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                    </b-row>
                    </b-tabs>
                    </div>
                    </b-overlay>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { germplasmRequestStore, germplasmDistribution, getDataByRecieverCode, getDataByOrgId, getAccessionInfo } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.formData = this.getCustomDataList(temps)
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        org_id: 0,
        request_type: 1,
        gn_orgazination_id: '',
        gn_organization_name: '',
        gn_organization_name_bn: '',
        gn_receiver_info_id: '0',
        receiver_name: '',
        receiver_name_bn: '',
        dis_date: '',
        accession_number: '',
        unit_id: '',
        quantity: '',
        remarks: '',
        remarks_bn: '',
        farmer_name: '',
        farmer_name_bn: '',
        contact_no: '',
        email: '',
        reason: '',
        reason_bn: '',
        distribute_quantity: '',
        dis_remarks: '',
        dis_remarks_bn: '',
        address: '',
        address_bn: '',
        contact_number: '',
        trade_license: '',
        crop_type: '',
        crop_name: '',
        characterization_details: '',
        characterization_type: '',
        local_name: '',
        scientific_name: '',
        english_name: '',
        conservation_type: '',
        production_date: '',
        current_date: '',
        sale_center_id: '',
        fertilizer_type_id: '',
        dealer_name: '',
        fertilizer_name_id: '',
        dealer_com_farmer_id: '',
        bill_no: '',
        tin_no: '',
        n_id: '',
        phone_no: '',
        status: '1'
      },
      loading: false,
      tab: 'active',
      tab2: '',
      rule1: '',
      rule2: '',
      rule3: '',
      select: true,
      dateerror: null,
      select2: false,
      select3: false,
      districtList: [],
      upazilaList: [],
      options: [
          { item: 1, name: this.$t('germplasm.germplasm_organization') },
          { item: 2, name: this.$t('germplasm.germplasm_receiver') },
          { item: 3, name: this.$t('germplasm.farmer') }
        ]
    }
  },
  computed: {
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    gpOrgList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpOrganizationsList.filter(item => item.status === 1)
    },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.filter(item => item.status === 1)
    }
  },
  watch: {
  'formData.request_type': function (newVal, oldVal) {
      if (this.id) {
        if (newVal === 1) {
            this.select = true
            this.rule1 = 'required|min_value:1'
            this.select2 = false
            this.rule2 = ''
            this.select3 = false
            this.rule3 = ''
        } else if (newVal === 2) {
            this.select = false
            this.rule1 = ''
            this.select2 = true
            this.rule2 = 'required'
            this.select3 = false
            this.rule3 = ''
        } else {
            this.select = false
            this.rule1 = ''
            this.select2 = false
            this.rule2 = ''
            this.select3 = true
            this.rule3 = 'required'
        }
      } else {
        if (newVal === 1) {
          this.formData.receiver_name = ''
          this.formData.receiver_name_bn = ''
          this.formData.nid = ''
          this.formData.trade_license = ''
          this.formData.gn_orgazination_id = ''
          this.formData.gn_receiver_info_id = ''
          this.formData.accession_number = ''
          this.formData.request_date = ''
            this.select = true
            this.rule1 = 'required|min_value:1'
            this.select2 = false
            this.rule2 = ''
            this.select3 = false
            this.rule3 = ''
        } else if (newVal === 2) {
            this.select = false
            this.rule1 = ''
            this.select2 = true
            this.rule2 = 'required'
            this.select3 = false
            this.rule3 = ''
        } else {
          // this.formData.contact_no = ''
          this.formData.receiver_name = ''
          this.formData.nid = ''
          // this.formData.address_bn = ''
          // this.formData.address = ''
          this.formData.tin = ''
          // this.formData.email = ''
          this.formData.trade_license = ''
          // this.formData.gn_orgazination_id = ''
          // this.formData.gn_receiver_info_id = ''

            this.select = false
            this.rule1 = ''
            this.select2 = false
            this.rule2 = ''
            this.select3 = true
            this.rule3 = 'required'
        }
      }
    },
    'formData.gn_orgazination_id': function (newVal, oldVal) {
      if (newVal) {
        this.districtList = this.getOrgData(newVal)
      }
    },
    'formData.gn_receiver_info_id': function (newVal, oldVal) {
      if (newVal) {
        this.districtList = this.getReceiverData(newVal)
      }
    },
    'formData.accession_number': function (newVal, oldVal) {
      if (newVal) {
        this.districtList = this.getAccessionData(newVal)
      }
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
       if (!this.formData.dis_date) {
         this.dateerror = 'Enter a Valid Date'
       } else {
          if (this.formData.request_type === 1) {
            this.formData.contact_no = ''
            this.formData.farmer_name = ''
            this.formData.farmer_name_bn = ''
            this.formData.gn_receiver_info_id = ''
          } else if (this.formData.request_type === 2) {
            this.formData.farmer_name = ''
            this.formData.farmer_name_bn = ''
            this.formData.gn_orgazination_id = ''
          } else {
            this.formData.gn_receiver_info_id = ''
            this.formData.gn_orgazination_id = ''
          }

          this.$store.dispatch('mutateCommonProperties', { loading: true })
          this.loading = true
          let result = null
          const loadinState = { loading: false, listReload: false }

          if (this.formData.id) {
            result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${germplasmDistribution}/${this.id}`, this.formData)
          } else {
            result = await RestApi.postData(seedFertilizerServiceBaseUrl, germplasmRequestStore, this.formData)
          }

          loadinState.listReload = true

          this.$store.dispatch('mutateCommonProperties', loadinState)
          this.loading = false

          if (result.success) {
            this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
            this.$toast.success({
              title: 'Success',
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
          } else {
            this.$refs.form.setErrors(result.errors)
          }
        }
    },
    getCustomDataList (data) {
        const collectionObj = this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.find(item => item.value === parseInt(data.unit_id))
        const collectionData = {}
        if (typeof collectionObj !== 'undefined') {
          collectionData.unit = collectionObj.text_en
          collectionData.unit_bn = collectionObj.text_bn
        } else {
          collectionData.unit = ''
          collectionData.unit_bn = ''
        }

        const orgObj = this.$store.state.SeedsFertilizer.commonObj.gpOrganizationsList.find(item => item.value === parseInt(data.gn_orgazination_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }
        const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(cropName => cropName.value === parseInt(data.crop_name_id))
            const customisedItem = {}
            if (typeof cropNameObj !== 'undefined') {
              const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(cropType => cropType.value === parseInt(cropNameObj.crop_type_id))
              if (typeof cropTypeObj !== 'undefined') {
                customisedItem.crop_type = cropTypeObj.text_en
                customisedItem.crop_type_bn = cropTypeObj.text_bn
              } else {
                customisedItem.crop_type = ''
                customisedItem.crop_type_bn = ''
              }
              customisedItem.crop_name = cropNameObj.text_en
              customisedItem.crop_name_bn = cropNameObj.text_bn
              customisedItem.scientific_name = cropNameObj.scientific_name
              customisedItem.english_name = cropNameObj.english_name
              customisedItem.local_name = cropNameObj.local_name
            } else {
              customisedItem.crop_name = ''
              customisedItem.crop_name_bn = ''
              customisedItem.crop_type = ''
              customisedItem.crop_type_bn = ''
            }

        const formdata = Object.assign({}, this.formData, collectionData, orgData, customisedItem)
        return formdata
    },
    getActualQuantity (event) {
        const currentId = '#' + event.target.id
        const idValAmount = document.querySelector(currentId).value
        if (idValAmount > this.formData.quantity) {
          const remainingIdVal = Math.floor(idValAmount / 10)
          this.formData.distribute_quantity = remainingIdVal
        }
    },
    getOrgData (orgId = null) {
      RestApi.getData(seedFertilizerServiceBaseUrl, getDataByOrgId + orgId).then(response => {
        if (response.success) {
          this.formData.contact_no = response.data.phone_no
          this.formData.email = response.data.email
          this.formData.address = this.$i18n.locale === 'en' ? response.data.address : response.data.address_bn
          this.formData.address_bn = response.data.address_bn
          this.formData.tin = response.data.tin_no
        } else {
          this.formData.contact_no = ''
          this.formData.email = ''
          this.formData.address = ''
          this.formData.address_bn = ''
          this.formData.tin = ''
        }
      })
    },
    getReceiverData (recCode = null) {
      RestApi.getData(seedFertilizerServiceBaseUrl, getDataByRecieverCode + recCode).then(response => {
        if (response.success) {
          this.formData.contact_no = response.data.phone_no
          this.formData.receiver_name = response.data.receiver_name
          this.formData.nid = response.data.nid
          this.formData.address_bn = response.data.address_bn
          this.formData.address = this.$i18n.locale === 'en' ? response.data.address : response.data.address_bn
          this.formData.trade_license = response.data.trade_license_no
          this.formData.tin = response.data.tin_no
          this.formData.email = response.data.email
        } else {
          this.formData.contact_no = ''
          this.formData.receiver_name = ''
          this.formData.nid = ''
          this.formData.address_bn = ''
          this.formData.address = ''
          this.formData.trade_license = ''
          this.formData.tin = ''
          this.formData.email = ''
        }
      })
    },
    getAccessionData () {
      RestApi.getData(seedFertilizerServiceBaseUrl, getAccessionInfo, this.formData).then(response => {
        if (response.success) {
          this.formData.crop_type = this.$i18n.locale === 'en' ? response.data.crop_type : response.data.crop_type_bn
          this.formData.crop_name = this.$i18n.locale === 'en' ? response.data.crop_name : response.data.crop_name_bn
          this.formData.scientific_name = this.$i18n.locale === 'en' ? response.data.scientific_name : response.data.scientific_name_bn
          this.formData.english_name = this.$i18n.locale === 'en' ? response.data.english_name : response.data.english_name_bn
          this.formData.local_name = this.$i18n.locale === 'en' ? response.data.local_name : response.data.local_name_bn
          this.formData.characterization_type = this.$i18n.locale === 'en' ? response.data.characterization_type : response.data.characterization_type_bn
          this.formData.characterization_details = this.$i18n.locale === 'en' ? response.data.characterization_details : response.data.characterization_details_bn
          this.formData.conservation_type = this.$i18n.locale === 'en' ? response.data.conservation_type : response.data.conservation_type_bn
        } else {
          this.formData.crop_type = ''
          this.formData.crop_name = ''
          this.formData.scientific_name = ''
          this.formData.english_name = ''
          this.formData.local_name = ''
          this.formData.characterization_type = ''
          this.formData.characterization_details = ''
          this.formData.conservation_type = ''
        }
      })
    }
  }
}
</script>
