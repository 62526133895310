<template>
    <b-container fluid>
        <iq-card>
             <template v-slot:body>
                <b-row>
                    <b-col class="col-lg-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="accession_number"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.accession_number')}}
                            </template>
                            <b-form-input
                              id="accession_number"
                              v-model="search.accession_number"
                            ></b-form-input>
                          </b-form-group>
                    </b-col>
                    <b-col class="col-sm-2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('germplasm.germplasm_distribution')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <!-- <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template> -->
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(quantity)="data">
                                            {{ $n(data.item.quantity, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(distribute_quantity)="data">
                                            {{ $n(data.item.distribute_quantity, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(request_type)="data">
                                            <div  v-if="data.item.request_type === 1">
                                            {{ $t('germplasm.germplasm_organization') }}
                                            </div>
                                            <div  v-else-if="data.item.request_type === 2">
                                            {{ $t('germplasm.germplasm_receiver') }}
                                            </div>
                                            <div v-else>
                                            {{ $t('germplasm.farmer') }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(name)="data">
                                          <span v-if="data.item.request_type ===1">
                                                {{ currentLocale === 'en' ? data.item.gn_organization_name : data.item.gn_organization_name_bn }}
                                            </span>
                                            <span v-else-if="data.item.request_type ===2">
                                                {{ currentLocale === 'en' ? data.item.receiver_name : data.item.receiver_name_bn }}
                                            </span>
                                            <span v-else>
                                                {{ currentLocale === 'en' ? data.item.farmer_name : data.item.farmer_name_bn }}
                                            </span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="fas fa-eye"></i></b-button>
<!--                                        <b-button v-if="data.item.status == 2" variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>-->
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 2 && data.item.distribute_quantity == 0">{{$t('globalTrans.approved')}}</span>
                                            <span class="badge badge-success" v-if="data.item.status == 2 && data.item.distribute_quantity > 0">{{$t('germplasm.distributed')}}</span>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('germplasm.germplasm_distribution') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('germplasm.germplasm_distribution') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { germplasmRequestList, germplasmRequestApprove, germplasmRequestDestroy } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                accession_number: '',
                id: 0,
                status: 2,
                org_id: this.$store.state.dataFilters.orgId
            },
            districtList: [],
            upazilaList: []
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('germplasm.germplasm_distribution') + ' ' + this.$t('globalTrans.entry') : this.$t('germplasm.germplasm_distribution') + ' ' + this.$t('globalTrans.update')
        },
        formTitleReject () {
            return this.$t('globalTrans.rejectMsg')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('germplasm.crop_type'), class: 'text-center' },
                { label: this.$t('germplasm.crop'), class: 'text-left' },
                { label: this.$t('germplasm.request_type'), class: 'text-left' },
                { label: this.$t('globalTrans.name'), class: 'text-left' },
                { label: this.$t('germplasm.approved_quantity'), class: 'text-center' },
                { label: this.$t('germplasm.distribute_quantity'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'crop_type_bn' },
                { key: 'crop_name_bn' },
                { key: 'request_type' },
                { key: 'name' },
                { key: 'quantity' },
                { key: 'distribute_quantity' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'crop_type' },
                { key: 'crop_name' },
                { key: 'request_type' },
                { key: 'name' },
                { key: 'quantity' },
                { key: 'distribute_quantity' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
            } else {
            return { text: item.text_en, value: item.value }
            }
        })
        return tempData
        },
        SalesCenterList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.SalesCenterList.filter(item => item.status === 1)
        },
        fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        gpOrgList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.gpOrganizationsList.filter(item => item.status === 1)
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
                if (divisionId) {
                    return districtList.filter(district => district.division_id === divisionId)
                }
            return districtList
        },
        edit (item) {
           this.editItemId = item.id
        },
        view (item) {
           this.editItemId = item.id
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, germplasmRequestDestroy, item, 'fertilizer', 'germplasmRequestList')
        },
        accept (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, germplasmRequestApprove, item, 'fertilizer', 'germplasmRequestList')
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            const params = Object.assign(this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            RestApi.getData(seedFertilizerServiceBaseUrl, germplasmRequestList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                } else {
                   this.$store.dispatch('setList', '')
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(cropName => cropName.value === parseInt(item.crop_name_id))
            const customisedItem = {}
            if(typeof cropNameObj !== 'undefined') {
              const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(cropType => cropType.value === parseInt(cropNameObj.crop_type_id))
              if(typeof cropTypeObj !== 'undefined') {
                customisedItem.crop_type = cropTypeObj.text_en
                customisedItem.crop_type_bn = cropTypeObj.text_bn
              } else {
                customisedItem.crop_type = ''
                customisedItem.crop_type_bn = ''
              }
              customisedItem.crop_name = cropNameObj.text_en
              customisedItem.crop_name_bn = cropNameObj.text_bn
            } else {
              customisedItem.crop_name = ''
              customisedItem.crop_name_bn = ''
              customisedItem.crop_type = ''
              customisedItem.crop_type_bn = ''
            }
            const gpOrg = this.gpOrgList.find(obj => obj.value === parseInt(item.gn_orgazination_id))
            customisedItem.gp_org_name = gpOrg?.text_en
            customisedItem.gp_org_name_bn = gpOrg?.text_bn
            return Object.assign({}, item, customisedItem)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        }
    }
}
</script>
<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
